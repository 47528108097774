import React from "react"
import styled, { css, keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

const lineAnim = keyframes`
  to {
  stroke-dashoffset: 0;
  }
`

const fill = keyframes`
to {
  fill: #fff;
}
`

const ClientsSvg = styled.svg`
  pointer-events: none;
  mix-blend-mode: difference;
  width: 100%;
  animation: ${({ inView }) =>
    inView
      ? css`
          ${fill} 0.8s ease forwards 2s;
        `
      : ""};

  path {
    stroke-dasharray: 147.14353942871094;
    stroke-dashoffset: 147.14353942871094;
    animation: ${({ inView }) =>
      inView
        ? css`
            ${lineAnim} 2s ease forwards;
          `
        : ""};
  }
  @media (max-width: 1240px) {
    margin-left: 0;
  }
`

const SvgClients = () => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
    triggerOnce: true,
  })
  return (
    <ClientsSvg
      inView={inView}
      id="svg-clients"
      ref={ref}
      viewBox="0 0 65 35"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3393 18.9393L25.2419 19.0367L25.1639 19.1502C22.0046 23.7456 17.7028 27.5666 12.6512 30.0474C9.57848 31.4864 6.22885 32.5366 2.79816 33.0136C2.69784 33.027 2.59753 33.0399 2.49722 33.0523L2.49944 27.301L3.31209 27.1849L3.34027 27.1809L3.36827 27.1758C10.3828 25.9004 16.4304 21.7585 20.248 16.032L20.4999 15.6541V15.5524C23.5292 11.06 27.7259 7.46747 32.6606 5.04667L32.7174 5.01879L32.7717 4.98622C33.1535 4.75714 33.5446 4.59767 34.0567 4.39283L34.057 4.39271L34.0801 4.38347L34.1029 4.37347C38.0194 2.65403 42.3146 1.69998 46.7999 1.69998C51.8673 1.69998 56.64 2.84595 60.935 4.94458C61.3815 5.16797 61.8616 5.42461 62.3349 5.69418C62.0169 6.68271 61.6352 7.58059 61.1224 8.41384L61.0878 8.47009L61.0583 8.52916C60.6196 9.40662 60.0345 10.2085 59.361 10.9309C55.5916 8.8619 51.3727 7.69998 46.7999 7.69998C43.0754 7.69998 39.474 8.55015 36.306 9.92257C33.3322 11.1975 30.6737 13.0049 28.4393 15.2393C27.3065 16.3721 26.275 17.604 25.3449 18.9337L25.3393 18.9393Z"
        stroke="white"
        strokeWidth="3"
      />
    </ClientsSvg>
  )
}

export default SvgClients
