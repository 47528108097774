import React from "react"
import styled, { css, keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

const lineAnim = keyframes`
  to {
  stroke-dashoffset: 0;
  }
`

const fill = keyframes`
  to {
  fill: #fff;
  }
`

const TwinSvg = styled.svg`
  pointer-events: none;
  mix-blend-mode: difference;
  width: 100%;
  animation: ${({ inView }) =>
    inView
      ? css`
          ${fill} 0.8s ease forwards 2s;
        `
      : ""};

  path:nth-child(1) {
    stroke-dasharray: 129.82777404785156;
    stroke-dashoffset: 129.82777404785156;
    animation: ${({ inView }) =>
      inView
        ? css`
            ${lineAnim} 2.5s ease forwards;
          `
        : ""};
  }
  path:nth-child(2) {
    stroke-dasharray: 313.6558532714844;
    stroke-dashoffset: 313.6558532714844;
    animation: ${({ inView }) =>
      inView
        ? css`
            ${lineAnim} 2.5s ease forwards;
          `
        : ""};
  }
`

const SvgTwin = () => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
    triggerOnce: true,
  })
  /* const items = document.querySelectorAll("#twin-svg path")
  for (let i = 0; i < items.length; i++) {
    console.log(`Litera ${i} ma ${items[i].getTotalLength()}`)
  } */

  return (
    <TwinSvg
      id="twin-svg"
      inView={inView}
      ref={ref}
      viewBox="0 0 165 68"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0543 63.7504C8.89421 58.4613 0.797982 45.9236 1.79569 31.9138C2.52214 22.9477 6.85053 15.0178 13.3109 9.66299C14.6894 11.1687 16.1901 12.5127 17.8103 13.7206C12.1983 18.1295 8.38291 24.7731 7.80441 32.3851C6.98274 42.9708 12.7212 52.5421 21.531 57.2806L21.0543 63.7504Z"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M154.77 13.4866L154.785 13.5039L154.801 13.5207C156.003 14.8152 157.042 16.3185 158.021 17.8834C160.883 22.6584 162.6 28.3887 162.6 34.4C162.6 40.8013 160.689 46.8244 157.533 51.7963L157.526 51.8081L157.519 51.8201C156.186 54.009 154.586 55.9786 152.729 57.5571L152.695 57.5856L152.663 57.6161C150.476 59.7086 147.898 61.5251 145.12 62.9629C140.721 65.1606 135.853 66.4 130.6 66.4C124.596 66.4 119.083 64.7699 114.277 61.9537C115.261 60.2124 116.508 58.5819 117.916 57.168C121.634 59.244 125.939 60.4 130.5 60.4C136.221 60.4 141.531 58.597 145.886 55.4106L145.912 55.3915L145.937 55.3713L145.967 55.3477C146.947 54.5633 148.002 53.7197 148.961 52.7607C150.554 51.1672 151.926 49.371 153.08 47.4822L153.113 47.4278L153.142 47.3708C153.224 47.2058 153.332 47.0112 153.461 46.7785L153.47 46.7632C153.589 46.5493 153.727 46.3003 153.842 46.0708L153.849 46.0569C154.043 45.6686 154.262 45.2305 154.481 44.6859C155.863 41.5916 156.6 38.0917 156.6 34.5C156.6 20.0676 144.824 8.39999 130.5 8.39999C117.592 8.39999 106.951 17.8828 104.834 30.1341C102.532 31.4095 100.368 32.9097 98.4003 34.6072C98.4001 34.5715 98.4 34.5357 98.4 34.5C98.4 16.8284 112.828 2.39999 130.5 2.39999C140.145 2.39999 148.84 6.69586 154.77 13.4866Z"
        stroke="white"
        strokeWidth="3"
      />
    </TwinSvg>
  )
}

export default SvgTwin
