import React from "react"
import styled from "styled-components"
import { useSpring, animated, config } from "react-spring"
import SvgClients from "../components/animation/svgClients"
import SvgCircle from "../components/animation/svgCircle"
import SvgTwin from "../components/animation/svgTwin"

const ContactPageWrapper = styled.main`
  overflow: hidden;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20vw;
  padding-right: 12vw;
  padding-bottom: 4rem;
  @media (max-width: 560px) {
    padding-right: 2rem;
    padding-left: calc(10vw + 40px);
  }
  .anim-wave {
    max-width: 3rem;
    position: absolute;
    bottom: 0;
    right: 25%;
  }
  .anim-circle {
    max-width: 3rem;
    position: absolute;
    top: 0;
    left: 0;
    @media (max-width: 879px) {
      left: 80%;
    }
  }
  .anim-twin {
    max-width: 6rem;
    position: absolute;
    bottom: 0;
    right: 25%;
    @media (max-width: 879px) {
      right: 0;
    }
  }
  .slogan {
    width: 100%;
    position: relative;
    h1 {
      overflow: hidden;
      text-align: right;
      font-size: 5rem;
      margin: 10rem 8rem 10rem 0;
      @media (max-width: 879px) {
        margin: 4rem 0;
      }
      @media (max-width: 560px) {
        text-align: left;
        margin-top: 8rem;
      }
    }
  }
  .info {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    @media (max-width: 560px) {
      flex-direction: column;
    }
    .adress {
      position: relative;
      width: 100%;
      flex-grow: 1;
      h2 {
        overflow: hidden;
        font-size: 2.8rem;
        margin: 8rem 0 4rem 0;
        line-height: 2rem;
        span {
          display: block;
        }
        @media (max-width: 560px) {
          margin: 2rem 0;
        }
      }
      span {
        display: block;
        margin-bottom: 1rem;
      }
    }
    .team {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 560px) {
        align-items: flex-start;
        order: -1;
      }
      p {
        max-width: 200px;
      }
      span {
        display: block;
        margin-bottom: 1rem;
      }
      a {
        text-decoration: none;
        display: block;
        color: inherit;
        margin-bottom: 1rem;
      }
    }
  }
`

const ContactPageTemplate = ({ data }) => {
  const fadeIn = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: "scale(0.91)" },
    opacity: 1,
    transform: "scale(1)",
  })

  const fadeInLeft = useSpring({
    delay: 2200,
    config: config.slow,
    from: { transform: "translate3d(-10%, 0, 0", opacity: 0 },
    to: { transform: "translate3d(0%,0,0)", opacity: 1 },
  })

  const fadeInRight = useSpring({
    delay: 2000,
    config: config.slow,
    from: { transform: "translate3d(10%, 0, 0", opacity: 0 },
    to: { transform: "translate3d(0%,0,0)", opacity: 1 },
  })

  return (
    <ContactPageWrapper>
      <div className="slogan">
        <animated.h1 style={fadeIn}>let's talk</animated.h1>
        <div className="anim-wave">
          <SvgClients />
        </div>
      </div>
      <div className="info">
        <div className="adress">
          <div className="anim-circle">
            <SvgCircle />
          </div>
          <div className="anim-twin">
            <SvgTwin />
          </div>
          <animated.div style={fadeInLeft}>
            <h2>
              <span>where</span>
              <span>to find</span>
              <span>us</span>
            </h2>
            <p
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              <span itemProp="streetAddress">Żabi kruk 14</span>
              <span itemProp="postalCode">80-822 Gdańsk</span>
              <span>POLAND</span>
            </p>
          </animated.div>
        </div>
        <animated.div style={fadeInRight} className="team">
          <p itemScope itemType="http://schema.org/Person">
            <span>marta</span>
            <a href="tel:+48660405252" itemProp="telephone">
              phone: + 48 660 405 252
            </a>
            <a href="mailto:contact@socolab.com.pl" itemProp="email">
              contact@socolab.com.pl
            </a>
          </p>
          <p itemScope itemType="http://schema.org/Person">
            <span>michał</span>
            <a href="tel:+48663669949" itemProp="telephone">
              phone: + 48 663 669 949
            </a>
            <a href="mailto:contact@socolab.com.pl" itemProp="email">
              contact@socolab.com.pl
            </a>
          </p>
        </animated.div>
      </div>
    </ContactPageWrapper>
  )
}

export default ContactPageTemplate
