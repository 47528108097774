import React from "react"
import styled, { css, keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

const lineAnim = keyframes`
  to {
  stroke-dashoffset: 0;
  }
`

const fill = keyframes`
  to {
  fill: #fff;
  }
`

const CircleSvg = styled.svg`
  pointer-events: none;
  mix-blend-mode: difference;
  width: 100%;
  animation: ${({ inView }) =>
    inView
      ? css`
          ${fill} 0.8s ease forwards 2s;
        `
      : ""};

  path {
    stroke-dasharray: 365.74505615234375;
    stroke-dashoffset: 365.74505615234375;
    animation: ${({ inView }) =>
      inView
        ? css`
            ${lineAnim} 2.5s ease forwards;
          `
        : ""};
  }
`

const SvgCircle = () => {
  const [ref, inView] = useInView({
    rootMargin: "0px 0px",
    triggerOnce: true,
  })

  return (
    <CircleSvg
      id="circle-svg"
      inView={inView}
      ref={ref}
      viewBox="0 0 68 68"
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.7 33.6C59.7 19.1676 47.9245 7.5 33.6 7.5C19.2755 7.5 7.5 19.1676 7.5 33.6C7.5 48.0284 19.1716 59.7 33.6 59.7C48.0284 59.7 59.7 48.0284 59.7 33.6ZM1.5 33.6C1.5 15.8313 15.9255 1.5 33.6 1.5C51.3716 1.5 65.7 15.8284 65.7 33.6C65.7 51.3716 51.3716 65.7 33.6 65.7C15.8313 65.7 1.5 51.2745 1.5 33.6Z"
        stroke="white"
        strokeWidth="3"
      />
    </CircleSvg>
  )
}

export default SvgCircle
