import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ContactPageTemplate from "../templates/contactPageTemplate"

const Contact = ({ data, location }) => {
  return (
    <Layout location={location}>
      <ContactPageTemplate data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { title: { eq: "contact" } }) {
      id
      frontmatter {
        title
      }
    }
  }
`

export default Contact
